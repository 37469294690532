import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './LanguageSelector.scss';

const SurveyPage: React.FC = () => {
  const { language, group } = useParams<{ language: string, group: string }>();
  const [iframeSrc, setIframeSrc] = useState<string>('');
  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    // 언어와 그룹에 따른 iframe URL 설정
    console.log(`Language: ${language}, Group: ${group}`); // 파라미터가 올바르게 전달되는지 확인

    if (language === 'ar' && group === 'a') {
      setIframeSrc('https://tally.so/r/3y0zl6?transparentBackground=1');
      setPageTitle('دراسة إحصائية حول تأثير تقنية الترجمة التوضيحية المعتمدة على الذكاء الاصطناعي على الوصول الثقافي للمحتوى الكوري');
    } else if (language === 'ar' && group === 'b') {
      setIframeSrc('https://tally.so/r/wdZvZd?transparentBackground=1');
      setPageTitle('دراسة إحصائية حول تأثير تقنية الترجمة التوضيحية المعتمدة على الذكاء الاصطناعي على الوصول الثقافي للمحتوى الكوري');
    } else if (language === 'de' && group === 'a') {
      setIframeSrc('https://tally.so/r/wo0z9x?transparentBackground=1');
      setPageTitle('Statistische Studie über die Auswirkungen der KI-basierten Untertiteltechnologie auf die kulturelle Zugänglichkeit koreanischer Inhalte');
    } else if (language === 'de' && group === 'b') {
      setIframeSrc('https://tally.so/r/nP2rRV?transparentBackground=1');
      setPageTitle('Statistische Studie über die Auswirkungen der KI-basierten Untertiteltechnologie auf die kulturelle Zugänglichkeit koreanischer Inhalte');
    } else if (language === 'en' && group === 'a') {
      setIframeSrc('https://tally.so/r/w5lpaN?transparentBackground=1');
      setPageTitle('Statistical study on the impact of AI-based subtitle technology on cultural accessibility of Korean content');
    } else if (language === 'en' && group === 'b') {
      setIframeSrc('https://tally.so/r/nGobxk?transparentBackground=1');
      setPageTitle('Statistical study on the impact of AI-based subtitle technology on cultural accessibility of Korean content');
    } else if (language === 'es' && group === 'a') {
      setIframeSrc('https://tally.so/r/wLEO9v?transparentBackground=1');
      setPageTitle('Estudio estadístico sobre el impacto de la tecnología de subtítulos basada en IA en la accesibilidad cultural del contenido coreano');
    } else if (language === 'es' && group === 'b') {
      setIframeSrc('https://tally.so/r/mDXaBR?transparentBackground=1');
      setPageTitle('Estudio estadístico sobre el impacto de la tecnología de subtítulos basada en IA en la accesibilidad cultural del contenido coreano');
    } else if (language === 'fr' && group === 'a') {
      setIframeSrc('https://tally.so/r/wMrVDM?transparentBackground=1');
      setPageTitle('Étude statistique sur l’impact de la technologie des sous-titres basés sur l’IA sur l’accessibilité culturelle du contenu coréen');
    } else if (language === 'fr' && group === 'b') {
      setIframeSrc('https://tally.so/r/w2boaj?transparentBackground=1');
      setPageTitle('Étude statistique sur l’impact de la technologie des sous-titres basés sur l’IA sur l’accessibilité culturelle du contenu coréen');
    } else if (language === 'id' && group === 'a') {
      setIframeSrc('https://tally.so/r/3x0zX5?transparentBackground=1');
      setPageTitle('Studi statistik tentang dampak teknologi subtitle berbasis AI pada aksesibilitas budaya konten Korea');
    } else if (language === 'id' && group === 'b') {
      setIframeSrc('https://tally.so/r/31bRWl?transparentBackground=1');
      setPageTitle('Studi statistik tentang dampak teknologi subtitle berbasis AI pada aksesibilitas budaya konten Korea');
    } else if (language === 'jp' && group === 'a') {
      setIframeSrc('https://tally.so/r/wLEkzG?transparentBackground=1');
      setPageTitle('AI技術を利用した字幕技術が韓国コンテンツの文化的アクセシビリティに与える影響に関する統計調査');
    } else if (language === 'jp' && group === 'b') {
      setIframeSrc('https://tally.so/r/wA6ybe?transparentBackground=1');
      setPageTitle('AI技術を利用した字幕技術が韓国コンテンツの文化的アクセシビリティに与える影響に関する統計調査');
    } else if (language === 'ko' && group === 'a') {
      setIframeSrc('https://tally.so/r/wLE6xJ?transparentBackground=1');
      setPageTitle('AI 기반 자막 기술이 한국 콘텐츠의 문화적 접근성에 미치는 영향에 대한 통계 조사');
    } else if (language === 'ko' && group === 'b') {
      setIframeSrc('https://tally.so/r/mOjkaK?transparentBackground=1');
      setPageTitle('AI 기반 자막 기술이 한국 콘텐츠의 문화적 접근성에 미치는 영향에 대한 통계 조사');
    } else if (language === 'th' && group === 'a') {
      setIframeSrc('https://tally.so/r/nP2r15?transparentBackground=1');
      setPageTitle('การศึกษาเชิงสถิติเกี่ยวกับผลกระทบของเทคโนโลยีคำบรรยายที่ใช้ AI ต่อการเข้าถึงวัฒนธรรมของเนื้อหาเกาหลี');
    } else if (language === 'th' && group === 'b') {
      setIframeSrc('https://tally.so/r/3y012B?transparentBackground=1');
      setPageTitle('การศึกษาเชิงสถิติเกี่ยวกับผลกระทบของเทคโนโลยีคำบรรยายที่ใช้ AI ต่อการเข้าถึงวัฒนธรรมของเนื้อหาเกาหลี');
    } else if (language === 'vi' && group === 'a') {
      setIframeSrc('https://tally.so/r/3y0zBd?transparentBackground=1');
      setPageTitle('Nghiên cứu thống kê về tác động của công nghệ phụ đề dựa trên AI đối với khả năng tiếp cận văn hóa của nội dung Hàn Quốc');
    } else if (language === 'vi' && group === 'b') {
      setIframeSrc('https://tally.so/r/mJVo9R?transparentBackground=1');
      setPageTitle('Nghiên cứu thống kê về tác động của công nghệ phụ đề dựa trên AI đối với khả năng tiếp cận văn hóa của nội dung Hàn Quốc');
    } else if (language === 'zh' && group === 'a') {
      setIframeSrc('https://tally.so/r/mJVMA7?transparentBackground=1');
      setPageTitle('关于AI字幕技术对韩国内容文化可达性的影响的统计研究');
    } else if (language === 'zh' && group === 'b') {
      setIframeSrc('https://tally.so/r/wLEkMl?transparentBackground=1');
      setPageTitle('关于AI字幕技术对韩国内容文化可达性的影响的统计研究');
    } else if (language === 'ru' && group === 'a') {
      setIframeSrc('https://tally.so/r/wMrMYl?transparentBackground=1');
      setPageTitle('Статистическое исследование влияния технологии субтитров на основе ИИ на культурную доступность корейского контента');
    } else if (language === 'ru' && group === 'b') {
      setIframeSrc('https://tally.so/r/mKPGxA?transparentBackground=1');
      setPageTitle('Статистическое исследование влияния технологии субтитров на основе ИИ на культурную доступность корейского контента');
    } else if (language === 'pt' && group === 'a') {
      setIframeSrc('https://tally.so/r/nP2r15?transparentBackground=1');
      setPageTitle('Статистическое исследование влияния технологии субтитров на основе ИИ на культурную доступность корейского контента');
    } else if (language === 'pt' && group === 'b') {
      setIframeSrc('https://tally.so/r/3y012B?transparentBackground=1');
      setPageTitle('Статистическое исследование влияния технологии субтитров на основе ИИ на культурную доступность корейского контента');
    } else {
      setIframeSrc('');
      setPageTitle('설문 페이지를 찾을 수 없습니다.');
    }
    
  }, [language, group]);

  return (
    <>
      {/* iframe으로 설문 페이지 표시 */}
      {iframeSrc ? (
        <iframe
          src={iframeSrc}
          width="100%"
          height="100%"
          title={pageTitle}
        />
      ) : (
        <p>{pageTitle}</p> // iframeSrc가 없으면 메시지 표시
      )}

    </>
  );
};

export default SurveyPage;
