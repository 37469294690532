import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LanguageSelector.scss';

const LanguageSelector: React.FC = () => {
  const [globalABGroup, setGlobalABGroup] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // A/B 그룹을 로컬 스토리지에서 가져오거나 랜덤으로 할당
    let group = localStorage.getItem("globalABGroup");
    if (!group) {
      group = Math.random() >= 0.5 ? "A" : "B";
      localStorage.setItem("globalABGroup", group);
    }
    setGlobalABGroup(group);
  }, []);

  const surveyLinks: { [key: string]: { linkA: string; linkB: string } } = {
    ko: { linkA: "/survey/a/ko", linkB: "/survey/a/ko" },
    en: { linkA: "/survey/a/en", linkB: "/survey/a/en" },
    jp: { linkA: "/survey/a/jp", linkB: "/survey/a/jp" },
    zh: { linkA: "/survey/a/zh", linkB: "/survey/a/zh" },
    th: { linkA: "/survey/a/th", linkB: "/survey/a/th" },
    vi: { linkA: "/survey/a/vi", linkB: "/survey/a/vi" },
    id: { linkA: "/survey/a/id", linkB: "/survey/a/id" },
    de: { linkA: "/survey/a/de", linkB: "/survey/a/de" },
    es: { linkA: "/survey/a/es", linkB: "/survey/a/es" },
    fr: { linkA: "/survey/a/fr", linkB: "/survey/a/fr" },
    pt: { linkA: "/survey/a/pt", linkB: "/survey/a/pt" },
    ru: { linkA: "/survey/a/ru", linkB: "/survey/a/ru" },
    ar: { linkA: "/survey/a/ar", linkB: "/survey/a/ar" }
  };

  const handleLanguageClick = (language: string) => {
    if (!globalABGroup) return; // A/B 그룹이 아직 설정되지 않았다면 아무것도 하지 않음
    const { linkA, linkB } = surveyLinks[language];
    const selectedLink = globalABGroup === "A" ? linkA : linkB;
    navigate(selectedLink);
  };

  return (
    <div className="screen">
      <div className="header-navigation">
        <div className="content">
          <div className="title">
            <div className="text-wrapper">Change your language</div>
          </div>
        </div>
      </div>
      <div className="frame-wrapper">
        <div className="frame">
          {['ko', 'en', 'jp', 'zh', 'th', 'vi', 'id', 'de', 'es', 'fr', 'pt', 'ru', 'ar'].map(language => (
            <button
              key={language}
              className="TEXT-wrapper"
              onClick={() => handleLanguageClick(language)}
            >
              <div className="TEXT" data-language={language}>
                {language === 'ko' && '한국어'}
                {language === 'en' && 'English'}
                {language === 'jp' && '日本語'}
                {language === 'zh' && '中文'}
                {language === 'th' && 'ภาษาไทย'}
                {language === 'vi' && 'Tiếng Việt'}
                {language === 'id' && 'Bahasa Indonesia'}
                {language === 'de' && 'Deutsch'}
                {language === 'es' && 'Español'}
                {language === 'fr' && 'Français'}
                {language === 'pt' && 'Português'}
                {language === 'ru' && 'Русский'}
                {language === 'ar' && 'العربية'}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
