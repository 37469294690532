import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import SurveyPage from './SurveyPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LanguageSelector />} />
        <Route path="/survey/:group/:language" element={<SurveyPage />} />
      </Routes>
    </Router>
  );
}

export default App;
